<template>
  <ion-page>

    <!-- Page Content -->
    <ion-content>
      <!-- Header -->
      <ModalHeader page="setup-tickets" />
      <!-- Home Feed -->
      <div class="app-max-width">
        <!-- If User is Authorized -->
        <div v-if="isAuth === true">
          <!-- Ticket Type -->
          <ion-item>
            <div tabindex="0"></div><!-- bug fix for safari 14 -->
            <ion-label position="stacked">Ticket Type</ion-label>
            <ion-select placeholder="Select One" v-model="ticketType">
              <ion-select-option value="general-admission">General Admission</ion-select-option>
              <ion-select-option value="advance">Advance</ion-select-option>
              <ion-select-option value="dos">Day of show</ion-select-option>
              <ion-select-option value="vip">VIP</ion-select-option>
              <ion-select-option value="free">Free</ion-select-option>
              <ion-select-option value="other">Other</ion-select-option>
            </ion-select>
          </ion-item>
          <!-- Description -->
          <ion-item>
            <div tabindex="0"></div><!-- bug fix for safari 14 -->
            <ion-textarea v-model="description" placeholder="Description..."></ion-textarea>
          </ion-item>
          <!-- Price -->
          <ion-item>
            <div tabindex="0"></div><!-- bug fix for safari 14 -->
            <ion-input v-model="price" type="text" placeholder="Price"></ion-input>
          </ion-item>
          <!-- Actions -->
          <ion-item lines="none">
            <div tabindex="0"></div><!-- bug fix for safari 14 -->
            <ion-button slot="end" @click="addTickets()">
              Add Tickets
            </ion-button>
          </ion-item>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonItem, IonLabel, IonSelect, IonSelectOption, IonTextarea, IonButton, IonInput } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import doAuth from '../../services/auth';
import ModalHeader from '../../components/ModalHeader.vue';
import { useStore } from "vuex";
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'SetupTickets',
  components: {
    IonContent, IonPage, IonItem, IonLabel, IonSelect, IonSelectOption, IonTextarea, IonButton, IonInput, ModalHeader,
  },
  data() {
    return {
      isAuth: false
    }
  },
  beforeMount: function() {
    this.isAuth = doAuth()
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const ticketType = ref('general-admission');
    const description = ref(null);
    const price = ref(null);

    function addTickets() {
      var ticketData = {
        type: ticketType.value,
        description: description.value,
        price: price.value
      }
      store.commit('setTickets', ticketData)
      ticketType.value = 'general-admission'
      description.value = null
      price.value = null
    }

    return {
      ticketType, description, price, router, addTickets
    }
  }
});
</script>

<style scoped>

</style>